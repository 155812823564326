import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { Row } from 'state-domains/domain';

import { buildSaveListRowUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const saveListRow = (id: string, payload: any): Observable<Row> => {
    const url = buildSaveListRowUrl(id, payload._id ?? '');
    return sendRequestWithXSRFToken(url, payload, payload._id ? 'PUT' : 'POST').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedListRow = convertToCamel<Row>(response);
            return observableOf(convertedListRow);
        }),
    );
};
